import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Home from './Home';
import Services from './Services';
import Contact from './Contactus';

function App() {
  return (
    <Router forceRefresh={true}>
    <Routes >
      <Route path="/" element={<Home/>} />
      <Route path="/*" element={<Home/>} />      
      <Route path="/Home" element={<Home/>} />
      <Route path="/Contact" element={<Contact />} /> 
    </Routes>
  </Router>
  
  );
}

export default App;
