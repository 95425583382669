import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "./Navbar";
import Contact from './Contactus';
import Banner from './Banner';
const locations="/";
export default function () {
  const navigate = useNavigate()
  return <div>
        <div className="sidenav">
      <img src="img/logo.png" />
      <a href=""></a>
      <a href="#product"></a>
      <a href="#services"></a>
      <a className="nav-link navitemhome" onClick={(e)=>window.location=''}><button className="dropdown-btn active">Home</button></a>
      <a className="nav-link navitemhome" onClick={(e)=>window.location='#products'}><button className="dropdown-btn ">Products</button></a>
      <a className="nav-link navitemhome" onClick={(e)=>window.location='#services'}><button className="dropdown-btn ">Services</button></a>
      <Link className="nav-link navitemhome" to="Contact"><button className="dropdown-btn ">Contact us</button></Link>
      {/* <a href="#partners"><button className="dropdown-btn ">Partners</button></a>
      <a href="#"><button className="dropdown-btn ">Resources</button></a> */}
      <a href="Contact"></a>
    </div>
    <div className="outing" />
    <Header/>
    <div className="container-fluid">
      <div className="js langSelect">
        <div className="language-picker js-language-picker" data-trigger-class="btn btn--subtle">
          <form action className="language-picker__form">
            <label htmlFor="language-picker-select">Select your language</label>
            <select name="language-picker-select" id="language-picker-select">
              <option lang="de" value="english" selected>English</option>
              <option lang="en" value="arabic">Arabic</option>
            </select>
          </form>
        </div>
      </div>
    </div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <a className="nav-link navitemhome" href="#">Home</a>
            <hr />
          </li>
          <li className="nav-item">
          <a className="nav-link navitemhome" onClick={locations+'#products'}>Products</a>
          </li>
          <li className="nav-item">
            <a className="nav-link navitemhome" onClick={(e)=>window.location='#services'}>Services</a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link navitemhome" href="#partners">Partners</a>
          </li>
          <li className="nav-item">
            <a className="nav-link navitemhome" href="#">Resources</a>
          </li> */}
          <li className="nav-item ">
          <Link className="nav-link navitemhome" to="Contact">Contact us</Link>
          </li>
        </ul>
      </div>
    </nav>
    {/*side bar Mobile*/}
    <div className="headerMob">
      <i className="fa-solid fa-bars sideBarShow" />
    </div>
    {/*side bar Mobile*/}
    {/*slog section*/}
    <Banner/>
    {/*end slog section*/}
    {/*start */}
    <section className="aboutUsSec">
      <img src="img/Pattern.png" />
      <div className="aboutInfo">
        <div className="container">
          <h1 data-aos="fade-up" data-aos-duration={2000}>WHO <span>WE ARE?</span></h1>
          <p className="text-justify" data-aos="fade-up" data-aos-duration={2500}>Tanfeth is provides graphic designing,  web  development, and Mobile applications development. Built your online appearance with Tanfeth and gets all the resources with pure quality, creativity, and consistency and always the client satisfaction rate is 95%. We always prioritize the client and their idea and innovation.</p>
        </div>
      </div>
    </section>

    <section className="ourGoals">
      <div className="container">
        <div className="row">
          <div className="col-md-6" data-aos="fade-right" data-aos-offset={300} data-aos-easing="ease-in-sine">
            <div className="goalBox">
              <div className="row">
                <div className="col-md-4">
                  <img src="img/Vision Icon.png" />
                  <h5>OUR<span> VISION</span></h5>
                </div>
                <div className="col-md-8 text-justify">
                  <p>As a creative agency, we are responsible for digitizing your imagination with pure quality, creativity, and consistency.</p>
                </div>
              </div>{/*row*/}
            </div>{/*goalBox*/}
          </div>
          <div className="col-md-6" data-aos="fade-left" data-aos-offset={300} data-aos-easing="ease-in-sine">
            <div className="goalBox">
              <div className="row">
                <div className="col-md-4">
                  <img src="img/Miision Icon.png" />
                  <h5>OUR<span> MISSION</span></h5>
                </div>
                <div className="col-md-8 text-justify">
                  <p>To help creators who have some insight into creative work. Tanfeth will always stand by them all. </p>
                </div>
              </div>{/*row*/}
            </div>{/*goalBox*/}
          </div>
          <div className="col-md-12" data-aos="fade-up" data-aos-duration={3000}>
            <div className="goalBox">
              <div className="row">
                <div className="col-md-4">
                  <img src="img/Specialization Icon.png" />
                  <h5><span> SPECIALIZATION</span></h5>
                </div>
                <div className="col-md-8 text-justify">
                  <p>Our collaborative efforts are tenacious and devoted. Tanfeth works with a variety of businesses, including local, corporate, and international clients. Additionally, 95% of clients are satisfied. The client's concept and creativity are always given priority.</p>
                </div>
              </div>{/*row*/}
            </div>{/*goalBox*/}
          </div>
        </div>
      </div>
    </section>


    {/*start Services*/}
    <section className="ServiceSec center pt-5" id="services">
      <h1>Our Services</h1>
      <div id="cards_landscape_wrap-2">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" data-aos="slide-up" data-aos-duration={900}>
              <a href="">
                <div class="card-flyer">
                  <div class="text-box">
                    <div class="image-box">
                      <img src="img/web.svg" alt=""/>
                    </div>
                    <div class="text-container">
                      <h6>Web development</h6>
                      <p className="text-justify"> Web development and design as well as web hosting are among our main offerings. We have previously created a number of attractive and highly functional websites for our clients all around the world.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" data-aos="slide-up" data-aos-duration={1000}>
              <a href="">
                <div class="card-flyer">
                  <div class="text-box">
                    <div class="image-box">
                      <img src="img/mobile.svg" alt=""/>
                    </div>
                    <div class="text-container">
                      <h6>Mobiles App</h6>
                      <p className="text-justify">We provide services for the whole application development lifecycle, including design, integration, and administration. The business oversees the full mobile app development process,  whether it is a consumer-focused app or a game-changing enterprise-class solution.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" data-aos="slide-up" data-aos-duration={1300}>
              <a href="">
                <div class="card-flyer">
                  <div class="text-box">
                    <div class="image-box">
                      <img src="img/Ui-Ux.svg" alt="" />
                    </div>
                    <div class="text-container">
                      <h6>UI / UX</h6>
                      <p className="text-justify">No digital work show its impression without eye catching graphics. As we are aim to be one stop shop for our business community so we offer high class graphics services. We have team of Professional Creative Graphic Designer</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>


    </section>
    {/*end Services*/}

    {/*start Products*/}
    <section className="ProductSec pt-5" id="products">
      <h1 data-aos="zoom-in" data-aos-duration={1000}>Our Latest Products</h1>
      <div class="container">

        <div class="row">

          <div class="col-lg-6 col-md-6 col-6 d-flex align-items-center justify-content-center" data-aos="zoom-in" data-aos-duration={1000}>
            <img src="img/Matbakh24-100.png" class="img-fluid" alt=""/>
          </div>

          {/* <div class="col-lg-4 col-md-4 col-6 d-flex align-items-center justify-content-center" data-aos="zoom-in" data-aos-duration={1300}>
            <img src="img/pixium-100.png" class="img-fluid" alt=""/>
          </div> */}

          <div class="col-lg-6 col-md-6 col-6 d-flex align-items-center justify-content-center" data-aos="zoom-in" data-aos-duration={1600}>
            <img src="img/Taxi24-100.png"  class="img-fluid" alt=""/>
          </div>
        </div>

      </div>
    </section>
    {/*end Services*/}


    {/*Start ourPartner*/}
    {/* <section className="ourPartner " id="partners">
      <div className="container">
        <h1>We are proud of our partners</h1>
        <div className="mainslider ">
          <div className="center slickslider ">
            <div className="item" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}><img src="img/Rectangle_bws.png" /></div>
            <div className="item" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}><img src="img/Rectangle_bws.png" /></div>
            <div className="item" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}><img src="img/Rectangle_bws.png" /></div>
            <div className="item" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}><img src="img/Rectangle_bws.png" /></div>
            <div className="item" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}><img src="img/Rectangle_bws.png" /></div>
            <div className="item" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}><img src="img/Rectangle_bws.png" /></div>
            <div className="item" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}><img src="img/Rectangle_bws.png" /></div>
          </div>
        </div>
      </div>
    </section> */}
    {/*end ourPartner*/}
    <footer>
      <div className="container" id="contact">
        <div className="row">
          <div className="col-md-6 ">
            <div className="contactInfo">
              <h4>Contacts</h4>
              <p>phone:<span> 0133311745</span></p>
              <p>Email:<span> info@tanfeth.com.sa</span></p>
            </div>
          </div>
          <div className="col-md-6 logoFoot">
            <img className="logo" src="img/Tanfeth logo - white.png" />
          </div>
        </div>{/*end row*/}
      </div>
    </footer>
    <div className="copyRight">
      <div className="container">
        <p>2022  Tanfeth All rights reserved</p>
      </div>
    </div>
  </div>
}