import { useNavigate } from "react-router-dom";
import Header from "./Navbar";
import Footer from "./Footer";

export default function (){
    const navigate = useNavigate()
    return  <div>
    <div className="sidenav">
      <img src="img/logo.png"  />
      <a onClick={(e)=>window.location=''}><button className="dropdown-btn ">Home</button></a>
      <a href="#products"><button className="dropdown-btn ">Products</button></a>
      <a href="#services"><button className="dropdown-btn  ">Services</button></a>
      {/* <a href="partners"><button className="dropdown-btn ">Partners</button></a>
      <a href="Resources"><button className="dropdown-btn ">Resources</button></a> */}
      <a href="Contact"><button className="dropdown-btn active">Contact Us</button></a>
    </div>
    <div className="outing" />
    <Header/>
    <div className="container-fluid">
      <div className="js langSelect">
        <div className="language-picker js-language-picker" data-trigger-class="btn btn--subtle">
          <form action className="language-picker__form">
            <label htmlFor="language-picker-select">Select your language</label>
            <select name="language-picker-select" id="language-picker-select">
              <option lang="en" value="english" selected>English</option>
              <option lang="ar" value="arabic">Arabic</option>
            </select>
          </form>
        </div>
      </div>
    </div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <a className="nav-link navitemhome" onClick={(e)=>window.location='/'}>Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link navitemhome active" href="#products">Products</a>
          </li>
          <li className="nav-item">
            <a className="nav-link navitemhome" href="#services">Services</a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link navitemhome" href="Parteners">Partners</a>
          </li>
          <li className="nav-item">
            <a className="nav-link navitemhome" href="Resources">Resources</a>
          </li> */}
          <li className="nav-item ">
            <a className="nav-link navitemhome borderRightNone" href="Contact">Contact Us</a>
            <hr />
          </li>
        </ul>
      </div>
    </nav>
    {/*side bar Mobile*/}
    <div className="headerMob">
      <i className="fa-solid fa-bars sideBarShow" />
    </div>

    {/*start */}
    <section className="aboutUsSec">
   
    <div class="container">
		<div class="section-contact">
			<div class="row justify-content-center">
				<div class="col-12 col-lg-10 col-xl-8">
					<div class="header-section text-center">
						<h2 class="title">Get In Touch
							<span class="dot"></span>
							<span class="big-title">CONTACT</span>
						</h2>
					</div>
				</div>
			</div>
			<div class="form-contact">
				<form action="/contact.php" method="post">
					<div class="row">
						<div class="col-md-6">
							<div class="single-input">
								<i class="fas fa-user"></i>
								<input type="text" name="name" required placeholder="ENTER YOUR NAME" className=""/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="single-input">
								<i class="fas fa-envelope"></i>
								<input type="text" name="email" required placeholder="ENTER YOUR EMAIL"/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="single-input">
								<i class="fas fa-phone"></i>
								<input type="text" name="phoneNumber" placeholder="ENTER YOUR PHONE NUMBER"/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="single-input">
								<i class="fas fa-check"></i>
								<input type="text" name="subject" required placeholder="ENTER YOUR SUBJECT"/>
							</div>
						</div>
						<div class="col-12">
							<div class="single-input">
								<i class="fas fa-comment-dots"></i>
								<textarea name="message" placeholder="ENTER YOUR MESSAGE" required></textarea>
							</div>
						</div>
						<div class="col-12">
							<div class="submit-input text-center">
								<input type="submit" name="submit" value="SUBMIT NOW"/>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
    </section>

    {/*end ourPartner*/} 
    <footer>
      <div className="container" id="contact">
        <div className="row">
          <div className="col-md-6 ">
            <div className="contactInfo">
              <h4>Contacts</h4>
              <p>phone:<span> 0133311745</span></p>
              <p>Email:<span> info@tanfeth.com.sa</span></p>
            </div>
          </div>
          <div className="col-md-6 logoFoot">
            <img className="logo" src="img/Tanfeth logo - white.png" />
          </div>
        </div>{/*end row*/} 
      </div>
    </footer>  
    <div className="copyRight">
      <div className="container">
        <p>2022  Tanfeth All rights reserved</p>           
      </div>
    </div>
  </div>
}