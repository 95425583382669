export default function Navbar(){
    return   <header>
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-6">
          <a href="/"><img className="logo" src="img/logo.png" /></a>
        </div>
        <div className="col-md-6 socialIcon">          
          <a className="socialIcon" target="blank" href="https://www.linkedin.com/in/tanfethinfo/">
            <img src="img/linkinIcon.png" />
          </a>
          <a className="socialIcon" target="blank" href="https://twitter.com/Tanfethinfo">
            <img src="img/twitterIcon.png" />
          </a>
          <a className="socialIcon" target="blank" href="https://www.linkedin.com/company/tanfethinfo">
            <img src="img/instaIcon.png" />
          </a>
        </div>
      </div>{/*end row*/}
    </div>{/*end container-fluid*/}
  </header>
}