import React from 'react'
import { Carousel } from 'react-bootstrap';


export default function Banner() {
    return (
        <section className='mainCover'>
        <Carousel indicators={false}>
                
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="img/Covr.jpg"
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                    <div>
                            <h2>Tanfeth</h2>
                            <p>impress your empation.</p>
                            <a href='#services' className='btn btn'>Services</a>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="img/mainPageCover.png"
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                    <div>
                            <h2>Tanfeth</h2>
                            <p>impress your empation.</p>
                            <a href='#services' className='btn btn'>Services</a>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </section>
    )
}
